import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Avatar, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Firebase, { AuthContext } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const SignIn = () => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [remember, setRemember] = useState(localStorage.getItem('remember') === 'true');
    const [formData, setFormData] = useState({
        email: localStorage.getItem('email'),
        password: ''
    });
    const [fieldsErrors, setFieldsErrors] = useState({
        email: '',
        password: ''
    });

    const onChange = (event) => {
        setErrorMessage('');

        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        localStorage.setItem('remember', remember);
        localStorage.setItem('email', remember ? formData.email : '');

        const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const emailError = emailRegexp.test(formData.email) ? '' : 'Invalid Email';
        setFieldsErrors((prevState) => ({
            ...prevState,
            email: emailError
        }));
        const passwordError = formData.password ? '' : 'Invalid Password';
        setFieldsErrors((prevState) => ({
            ...prevState,
            password: passwordError
        }));

        if (emailError || passwordError) {
            return;
        }
        try {
            setSubmitted(true);
            await Firebase.auth().signInWithEmailAndPassword(formData.email, formData.password);
        } catch (error) {
            //TODO create meaningful error messages
            console.error(error);
            setErrorMessage(error.message ? error.message : 'Something went wrong.');
        }
        setSubmitted(false);
    };

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to={ROUTES.LANDING} />;
    }
    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={formData.email}
                        onChange={onChange}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        helperText={fieldsErrors.email ? fieldsErrors.email : null}
                        error={!!fieldsErrors.email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={formData.password}
                        onChange={onChange}
                        helperText={fieldsErrors.password ? fieldsErrors.password : null}
                        error={!!fieldsErrors.password}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="remember"
                                color="primary"
                                checked={remember}
                                onChange={(e) => {
                                    setRemember(e.target.checked);
                                }}
                            />
                        }
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={submitted}>
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="mailto:info@alz.life" variant="body2">
                                {"Don't have an account? Contact Us"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {errorMessage && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
        </Container>
    );
};

export default SignIn;
