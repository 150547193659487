import React, { useContext } from 'react';
import { AuthContext } from '../../components/Firebase';

const Admin = () => {
    // eslint-disable-next-line no-unused-vars
    const { currentUser } = useContext(AuthContext);
    <h1>Admin</h1>;
};

export default Admin;
