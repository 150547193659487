import React, { useContext } from 'react';
import Firebase, { AuthContext } from '../../components/Firebase';
import Typography from '@material-ui/core/Typography';
import * as ROUTES from '../../constants/routes';
import Link from '@material-ui/core/Link';
import { Redirect } from 'react-router-dom';

const Account = () => {
    const { currentUser } = useContext(AuthContext);

    if (!currentUser) return <Redirect to={ROUTES.SIGN_IN} />;

    return (
        <div>
            <Typography variant="h5" align="center">
                Account
            </Typography>
            ID:&nbsp;{currentUser._id}
            <br />
            Email:&nbsp;{currentUser.email}
            <br />
            Role:&nbsp;{currentUser.role}
            <br />
            Trial Code:&nbsp;{currentUser.trial_code}
            <br />
            Trial Group:&nbsp;{currentUser.trial_group}
            <br />
            <Link href={ROUTES.SIGN_IN} variant="body2" onClick={() => Firebase.auth().signOut()}>
                Sign out
            </Link>
        </div>
    );
};

export default Account;
