import React, { useEffect, useState, useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import { AuthContext } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import SessionList from '../sessions';
import { Paper, Typography } from '@material-ui/core';
import Moment from 'react-moment';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
momentDurationFormatSetup(moment);

// const useStyles = makeStyles({});

const Participant = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [participant, setParticipant] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const { code, id } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    // eslint-disable-next-line no-unused-vars
    // const classes = useStyles();

    useEffect(() => {
        const cancelToken = axios.CancelToken;
        const cancelTokenSource = cancelToken.source();
        let didCancel = false;

        const fetchData = async () => {
            try {
                if (!currentUser) return;
                setIsLoading(true);
                setErrorMessage(null);

                const token = await currentUser.getIdToken();
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_ENDPOINT}/trials/${code}/participants/${id}`,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    cancelToken: cancelTokenSource.token
                });

                setParticipant(response.data);
            } catch (e) {
                if (axios.isCancel(e)) {
                    console.log('Request canceled', e.message);
                } else {
                    console.log(`Request failed`, e.message);
                }
                if (!didCancel) {
                    setIsLoading(false);
                    setErrorMessage('Failed to load data. Try reloading the page');
                }
            }
            setIsLoading(false);
        };

        fetchData();

        return () => {
            didCancel = true;
            cancelTokenSource.cancel('Component got unmounted');
        };
    }, []);

    if (currentUser) {
        if (isLoading) {
            return (
                <div>
                    <CircularProgress />
                </div>
            );
        }
        if (errorMessage) {
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            );
        }
        if (participant) {
            return (
                <>
                    {' '}
                    <Typography variant="h5" align="center">
                        Trial: {code} Participant: {participant.email}
                    </Typography>
                    <Paper>
                        <Typography variant="h6" align="left">
                            Role: {participant.role}
                        </Typography>
                        <Typography variant="h6" align="left">
                            Trial Group: {participant.trial_group}
                        </Typography>
                        <Typography variant="h6" align="left">
                            Confirmed On:{' '}
                            {participant.timestamp_confirmed && (
                                <Moment>{participant.timestamp_confirmed}</Moment>
                            )}
                        </Typography>
                        <Typography variant="h6" align="left">
                            Timezone: {participant.timezone}
                        </Typography>
                        <Typography variant="h5" align="left">
                            Progress
                        </Typography>
                        {participant.progress && (
                            <>
                                <Typography variant="h6" align="left">
                                    Total Therapy Time:
                                    {participant.progress.total_activity_duration &&
                                        moment
                                            .duration(
                                                participant.progress.total_activity_duration,
                                                'milliseconds'
                                            )
                                            .format('h[h]:m[m]:s[s]')}
                                </Typography>
                                <Typography variant="h6" align="left">
                                    Average Daily Time:
                                    {participant.progress.avg_daily_activity_duration &&
                                        moment
                                            .duration(
                                                participant.progress.avg_daily_activity_duration,
                                                'milliseconds'
                                            )
                                            .format('h[h]:m[m]:s[s]')}
                                </Typography>
                                <Typography variant="h6" align="left">
                                    Games Completed: {participant.progress.total_round_count}
                                </Typography>
                                <Link
                                    href={`https://boca.alz.life/trials/${participant.trial_code}/participants/${participant.email}`}
                                    target="_blank">
                                    BoCa
                                </Link>
                            </>
                        )}
                    </Paper>
                    <Typography variant="h6" align="left">
                        Sessions
                    </Typography>
                    <SessionList rows={participant.sessions || []}></SessionList>
                </>
            );
        }
    } else {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }
};

export default Participant;
