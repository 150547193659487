import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { AuthContext } from '../../components/Firebase';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Get } from '../../adapters/xhr';
momentDurationFormatSetup(moment);

function descendingComparator(a, b, orderBy) {
    if (a[orderBy] === null || a[orderBy] === undefined) return 1;
    if (b[orderBy] === null || b[orderBy] === undefined) return -1;
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
    { id: 'trial_group', numeric: false, disablePadding: false, label: 'Group' },
    { id: 'first_seen', numeric: false, disablePadding: false, label: 'First seen' },
    { id: 'last_seen', numeric: false, disablePadding: false, label: 'Last seen' },
    { id: 'total_duration', numeric: false, disablePadding: false, label: 'Total time' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    title: {
        flex: '1 1 100%'
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

// eslint-disable-next-line react/prop-types
export default function Participants({ code }) {
    // eslint-disable-next-line react/prop-types
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('email');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const history = useHistory();
    const { url } = useRouteMatch();
    const { currentUser } = useContext(AuthContext);

    const res = Get(
        `${process.env.REACT_APP_API_ENDPOINT}/trials/${code}/participants`,
        currentUser
    );

    const rows = res.data || [];

    const EnhancedTableToolbar = () => {
        const classes = useToolbarStyles();

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: false
                })}>
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                {/*<Tooltip title="Refresh list">*/}
                {/*    <IconButton aria-label="refresh" onClick={() => fetchData()}>*/}
                {/*        <RefreshRoundedIcon />*/}
                {/*    </IconButton>*/}
                {/*</Tooltip>*/}
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {};

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // eslint-disable-next-line no-unused-vars
    const handleClick = (event, id) => {
        history.push(`${url}/participants/${id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            {res.isLoading && (
                <div>
                    <CircularProgress />
                </div>
            )}
            {res.error && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {res.error}
                </Alert>
            )}
            <Paper className={classes.paper}>
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table">
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row._id)}
                                            tabIndex={-1}
                                            key={row._id}>
                                            <TableCell align="default">{row.email}</TableCell>
                                            <TableCell align="left">{row.role}</TableCell>
                                            <TableCell align="left">{row.trial_group}</TableCell>
                                            <TableCell align="left">
                                                {row.first_seen && (
                                                    <Moment>{row.first_seen}</Moment>
                                                )}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.last_seen && <Moment>{row.last_seen}</Moment>}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.total_duration
                                                    ? moment
                                                          .duration(
                                                              row.total_duration,
                                                              'milliseconds'
                                                          )
                                                          .format('h[h]:m[m]:s[s]')
                                                    : ''}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
