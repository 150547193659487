import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthContext } from '../../components/Firebase';
import ParticipantList from '../participants';
import { Paper, Typography, List, ListItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Link from '@material-ui/core/Link';
import { Get } from '../../adapters/xhr';

const Trial = () => {
    const { currentUser } = useContext(AuthContext);
    const { code } = useParams();

    if (!currentUser) {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }

    const res = Get(`${process.env.REACT_APP_API_ENDPOINT}/trials/${code}`, currentUser);

    if (res.isLoading) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    if (res.error) {
        return (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {res.error}
            </Alert>
        );
    }

    if (res.data) {
        const trial = res.data;
        return (
            <>
                <Typography variant="h5" align="center">
                    Trial: {trial.code} - {trial.name}
                </Typography>
                <Typography variant="h6" align="left">
                    Administrators
                </Typography>
                <Paper>
                    <List>
                        {trial.administrators.map((a) => (
                            <ListItem key={a.email}>
                                <Link href={`mailto:${a.email}`} variant="body2">
                                    {a.email}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
                <Typography variant="h6" align="left">
                    Participants
                </Typography>
                <ParticipantList code={code} />
            </>
        );
    } else {
        return <div>No data...</div>;
    }
};

export default Trial;
