import React, { useContext } from 'react';
import { AccountCircleRounded, Home } from '@material-ui/icons';
import { Link as NavLink, useHistory } from 'react-router-dom';
import { AppBar, Toolbar, List, ListItem, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { AuthContext } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles({
    navDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`,
        whiteSpace: 'nowrap'
    }
});

const Navigation = () => {
    const { currentUser } = useContext(AuthContext);
    const classes = useStyles();
    const history = useHistory();

    return (
        <AppBar position="static">
            <Toolbar>
                <Container maxWidth="lg" className={classes.navbarDisplayFlex}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="home"
                        onClick={() => history.push(`${ROUTES.LANDING}`)}>
                        <Home fontSize="large" />
                    </IconButton>
                    <List
                        component="nav"
                        aria-labelledby="main navigation"
                        className={classes.navDisplayFlex}>
                        {currentUser ? (
                            <>
                                <ListItem
                                    component={NavLink}
                                    to={ROUTES.TRIALS}
                                    className={classes.linkText}>
                                    Trials
                                </ListItem>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="account"
                                    onClick={() => history.push(`${ROUTES.ACCOUNT}`)}>
                                    <AccountCircleRounded fontSize="large" />
                                </IconButton>
                            </>
                        ) : (
                            <ListItem
                                component={NavLink}
                                to={ROUTES.SIGN_IN}
                                className={classes.linkText}>
                                Sign in
                            </ListItem>
                        )}
                    </List>
                </Container>
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
