export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const TRIALS = '/trials';
export const TRIALS_ITEM = '/trials/:code';
export const PARTICIPANTS_ITEM = '/trials/:code/participants/:id';
export const SESSIONS_ITEM = '/trials/:code/participants/:email/sessions/:id';
export const ACTIVITIES_ITEM = '/trials/:code/participants/:email/sessions/:id/activities/:id';
export const PASSWORD_FORGET = '/pw-forget';
