import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import Firebase from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const PasswordForget = () => {
    const history = useHistory();
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        email: localStorage.getItem('email')
    });
    const [fieldsErrors, setFieldsErrors] = useState({
        email: ''
    });

    const onChange = (event) => {
        setErrorMessage('');

        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const emailError = emailRegexp.test(formData.email) ? '' : 'Invalid Email';
        setFieldsErrors((prevState) => ({
            ...prevState,
            email: emailError
        }));

        if (emailError) {
            return;
        }

        const { email } = e.target.elements;
        try {
            setSubmitted(true);
            await Firebase.auth().sendPasswordResetEmail(email.value);
            alert('Please check your email for password reset instructions!');
            history.push(`${ROUTES.SIGN_IN}`);
        } catch (error) {
            alert(error);
        }
        setSubmitted(false);
    };
    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forgot Password?
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={formData.email}
                        onChange={onChange}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        helperText={fieldsErrors.email ? fieldsErrors.email : null}
                        error={!!fieldsErrors.email}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={submitted}>
                        Reset
                    </Button>
                </form>
            </div>
            {errorMessage && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
        </Container>
    );
};

export default PasswordForget;
