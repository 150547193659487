import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Typography, Paper } from '@material-ui/core';
import Moment from 'react-moment';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import Activities from '../activities';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Get } from '../../adapters/xhr';
momentDurationFormatSetup(moment);

const Session = () => {
    const { currentUser } = useContext(AuthContext);
    const { id } = useParams();

    if (!currentUser) {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }

    const res = Get(`${process.env.REACT_APP_API_ENDPOINT}/sessions/${id}`, currentUser);

    if (res.isLoading) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    if (res.error) {
        return (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {res.error}
            </Alert>
        );
    }

    if (res.data) {
        const session = res.data;
        return (
            <>
                <Typography variant="h5" align="center">
                    Session: {session._id}
                </Typography>
                <Paper>
                    <Typography variant="h6" align="left">
                        Started on:&nbsp;
                        {session.timestamp_started && <Moment>{session.timestamp_started}</Moment>}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Last seen on:&nbsp;
                        {session.timestamp_last_seen && (
                            <Moment>{session.timestamp_last_seen}</Moment>
                        )}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Duration:&nbsp;
                        {session.duration &&
                            moment
                                .duration(session.duration, 'milliseconds')
                                .format('h[h]:m[m]:s[s]')}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Timezone: {session.timezone || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Language: {session.language || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Device Model: {session.device_model || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Device System: {session.device_system || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Device UUID: {session.device_uuid || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        FPS: {session.fps}
                    </Typography>
                    <Typography variant="h6" align="left">
                        App Version: {session.app_version || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Subscribed:
                        {session.is_subscribed != null && session.is_subscribed != undefined
                            ? session.is_subscribed.toString()
                            : ''}
                    </Typography>
                </Paper>
                <Typography variant="h6" align="left">
                    Activities
                </Typography>
                <Activities rows={session.activities}></Activities>
            </>
        );
    }
};

export default Session;
