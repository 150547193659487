import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import Moment from 'react-moment';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { Typography } from '@material-ui/core';
momentDurationFormatSetup(moment);

// eslint-disable-next-line react/prop-types
const Rounds = ({ activity }) => {
    // eslint-disable-next-line react/prop-types
    const rounds = [];
    // eslint-disable-next-line react/prop-types
    if (activity.rounds && Array.isArray(activity.rounds)) {
        // eslint-disable-next-line react/prop-types
        for (let i = 0; i < activity.rounds.length; i++) {
            // eslint-disable-next-line react/prop-types
            const round = activity.rounds[i];
            rounds.push(
                <>
                    <Typography variant="h6" align="left">
                        Round: {i}
                    </Typography>
                    <Typography variant="h6" align="left">
                        {/* eslint-disable-next-line react/prop-types */}
                        Level: {round.level}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Ended: {/* eslint-disable-next-line react/prop-types */}
                        {round.timestamp_completed && <Moment>{round.timestamp_completed}</Moment>}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Duration: {/* eslint-disable-next-line react/prop-types */}
                        {round.duration &&
                            moment
                                // eslint-disable-next-line react/prop-types
                                .duration(round.duration, 'milliseconds')
                                .format('h[h]:m[m]:s[s]')}
                    </Typography>
                    <Typography variant="h6" align="left">
                        {/* eslint-disable-next-line react/prop-types */}
                        Correct Answers: {round.correct_answers ?? ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Average Reaction Time:
                        {/* eslint-disable-next-line react/prop-types */}
                        {round.average_reaction_time &&
                            moment
                                // eslint-disable-next-line react/prop-types
                                .duration(round.average_reaction_time, 'milliseconds')
                                .format('s.S[s]')}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Winning:
                        {/* eslint-disable-next-line react/prop-types */}
                        {round.is_winning === true || round.is_winning === false
                            ? // eslint-disable-next-line react/prop-types
                              round.is_winning.toString()
                            : ''}
                    </Typography>
                </>
            );
        }
    }
    return (
        <>
            <Typography variant="h5" align="left">
                {/* eslint-disable-next-line react/prop-types */}
                Rounds: {activity.round_count}
            </Typography>
            {rounds}
        </>
    );
};

const Activity = (props) => {
    const { currentUser } = useContext(AuthContext);
    // const { id } = useParams();
    const activity = props.location.activity;

    if (currentUser) {
        if (activity) {
            return (
                <>
                    <Typography variant="h5" align="center">
                        Activity: {activity._id}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Type: {activity.code}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Started:{' '}
                        {activity.timestamp_started && (
                            <Moment>{activity.timestamp_started}</Moment>
                        )}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Ended:{' '}
                        {activity.timestamp_ended && <Moment>{activity.timestamp_ended}</Moment>}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Duration: &nbsp;
                        {activity.duration &&
                            moment
                                .duration(activity.duration, 'milliseconds')
                                .format('h[h]:m[m]:s[s]')}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Light Level: {activity.light_level || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Sound Level: {activity.sound_level || ''}
                    </Typography>
                    <Typography variant="h6" align="left">
                        Trial: {activity.trial.toString()}{' '}
                    </Typography>
                    <Rounds activity={activity}></Rounds>
                </>
            );
        } else {
            //TODO handle error better
            return (
                <h2>Invalid activity or you don&apos;t have permission to view this information</h2>
            );
        }
    } else {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }
};

export default Activity;
