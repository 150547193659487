import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { useHistory } from 'react-router';
import Firebase from './firebase';
// import * as ROUTES from '../../constants/routes';

export const AuthContext = React.createContext();

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    // const history = useHistory();

    useEffect(() => {
        Firebase.auth().onAuthStateChanged(async (user) => {
            console.log('Firebase USER', user ? user.email : '');
            if (user) {
                try {
                    const token = await user.getIdToken();
                    // console.log('TOKEN', token);
                    const response = await axios({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_ENDPOINT}/users/${user.uid}`,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    });
                    // console.log('Response', response.data);
                    user._id = response.data._id;
                    user.role = response.data.role;
                    user.trial_code = response.data.trial_code;
                    user.trial_group = response.data.trial_group;
                } catch (e) {
                    console.log(`/users/${user.uid} Failed`, e);
                }
            }
            setCurrentUser(user);
            // history.push(ROUTES.SIGN_IN);
        });
    }, []);

    return <AuthContext.Provider value={{ currentUser }}>{children}</AuthContext.Provider>;
};
