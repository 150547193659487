import React, { useEffect, useState, useContext } from 'react';
import {
    Typography,
    Paper,
    Table,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    makeStyles
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../components/Firebase';
import { useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

const Trials = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [trials, setTrials] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { url } = useRouteMatch();
    const classes = useStyles();
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const handleClick = (event, code) => {
        history.push(`${url}/${code}`);
    };

    useEffect(() => {
        const cancelToken = axios.CancelToken;
        const cancelTokenSource = cancelToken.source();
        let didCancel = false;

        const fetchData = async () => {
            try {
                if (!currentUser) return;
                setIsLoading(true);
                setErrorMessage(null);

                const token = await currentUser.getIdToken();
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_ENDPOINT}/trials`,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    cancelToken: cancelTokenSource.token
                });

                const trials = response.data;
                setTrials(trials);
            } catch (e) {
                if (axios.isCancel(e)) {
                    console.log('Request canceled', e.message);
                } else {
                    console.log(`Request failed`, e.message);
                }
                if (!didCancel) {
                    setIsLoading(false);
                    setErrorMessage('Failed to load data. Try reloading the page');
                }
            }

            setIsLoading(false);
        };

        fetchData();

        return () => {
            // console.log('CLEANUP');
            didCancel = true;
            cancelTokenSource.cancel('Component got unmounted');
        };
    }, []);

    if (currentUser) {
        return (
            <>
                <Typography variant="h5" align="center">
                    My Trials
                </Typography>
                {isLoading && (
                    <div>
                        <CircularProgress />
                    </div>
                )}
                {errorMessage && (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert>
                )}
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell align="left">Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trials.map((t) => (
                                <TableRow
                                    key={t.code}
                                    onClick={(event) => handleClick(event, t.code)}>
                                    <TableCell component="th" scope="row">
                                        {t.code}
                                    </TableCell>
                                    <TableCell align="left">{t.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    } else {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }
};

export default Trials;
