import { useEffect, useState } from 'react';
import axios from 'axios';

export function Get(url, currentUser) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const cancelToken = axios.CancelToken;
        const cancelTokenSource = cancelToken.source();
        let didCancel = false;

        const fetchData = async () => {
            try {
                if (!currentUser) return;
                setIsLoading(true);
                setError(null);

                const token = await currentUser.getIdToken();

                const response = await axios({
                    method: 'GET',
                    url: url,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    cancelToken: cancelTokenSource.token
                });

                setData(response.data);
            } catch (e) {
                if (axios.isCancel(e)) {
                    console.log('Request canceled', e.message);
                } else {
                    console.log(`Request failed`, e.message);
                }
                if (!didCancel) {
                    setIsLoading(false);
                    setError('Failed to load data. Try again.');
                }
            }
            setIsLoading(false);
        };

        fetchData();

        return () => {
            didCancel = true;
            cancelTokenSource.cancel('Component got unmounted');
        };
    }, [url, setData, currentUser]);

    return { data, error, isLoading };
}
