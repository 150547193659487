import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, Typography } from '@material-ui/core';
import './index.css';
import App from './components/App';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://alz.life" target="_blank">
                Alzheimer&apos;s Light, LLC
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <Container>
            <App />
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    </React.StrictMode>,
    document.getElementById('root')
);
