import React, { useContext } from 'react';
import { AuthContext } from '../../components/Firebase/context';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Typography } from '@material-ui/core';

const Landing = () => {
    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return (
            <Typography variant="h5" align="center">
                Welcome to AlzLife
            </Typography>
        );
    } else {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }
};

export default Landing;
