import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from '../Navigation';
import LandingPage from '../../pages/landing';
import SignInPage from '../../pages/signin';
import PasswordForgetPage from '../../pages/passwordforget';
// import HomePage from '../../pages/home';
import AccountPage from '../../pages/account';
import AdminPage from '../../pages/admin';
import TrialsPage from '../../pages/trials';
import TrialDetailsPage from '../../pages/trials/show';
import ParticipantDetailsPage from '../../pages/participants/show';
import SessionDetailsPage from '../../pages/sessions/show';
import ActivityDetailsPage from '../../pages/activities/show';
import NotFound from '../../pages/error/notfound';
import { AuthProvider } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Navigation />
                <Switch>
                    <Route exact path={ROUTES.LANDING} component={LandingPage} />
                    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                    {/*<Route path={ROUTES.HOME} component={HomePage} />*/}
                    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                    <Route path={ROUTES.ADMIN} component={AdminPage} />
                    <Route exact path={ROUTES.TRIALS} component={TrialsPage} />
                    <Route exact path={ROUTES.TRIALS_ITEM} component={TrialDetailsPage} />
                    <Route
                        exact
                        path={ROUTES.PARTICIPANTS_ITEM}
                        component={ParticipantDetailsPage}
                    />
                    <Route exact path={ROUTES.SESSIONS_ITEM} component={SessionDetailsPage} />
                    <Route exact path={ROUTES.ACTIVITIES_ITEM} component={ActivityDetailsPage} />
                    <Route component={NotFound}></Route>
                </Switch>
            </Router>
        </AuthProvider>
    );
};

export default App;
